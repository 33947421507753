.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px;

}



.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}



.App-body {
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100vh - 100px;
}

.App-footer{
  background-color: #161b24;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
}


.linkedinIcon{
  border-radius: 25%;
  height: 25px;
  width: 25px;
}